import * as _defineProperties2 from "define-properties";
var _defineProperties = _defineProperties2;
try {
  if ("default" in _defineProperties2) _defineProperties = _defineProperties2.default;
} catch (e) {}
import * as _callBind2 from "call-bind";
var _callBind = _callBind2;
try {
  if ("default" in _callBind2) _callBind = _callBind2.default;
} catch (e) {}
import _implementation from "./implementation";
import _polyfill from "./polyfill";
import _shim from "./shim";
var exports = {};
var define = _defineProperties;
var callBind = _callBind;
var implementation = _implementation;
var getPolyfill = _polyfill;
var shim = _shim;
var polyfill = callBind(getPolyfill(), Object);
define(polyfill, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
exports = polyfill;
export default exports;